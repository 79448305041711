<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04"><img src="@/assets/front_component/images/assets/back02.png" alt="雲"></div>
      </div>
      <div class="left_area">
        <div class="img01"><img src="@/assets/front_component/images/assets/back01.png" alt="雲"></div>
      </div>
    </div>
    <div class="en_ttl en"><span>COMPANY POLICY</span></div>
    <div class="content_wrap">
      <h2>会社方針・部門方針</h2>
      <div class="mascot_wrap">
        <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君">
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li><router-link :to="{ name: 'Front top' }">ホーム</router-link></li>
            <li><span>会社方針・部門方針</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <article class="post_area">
              <!--
              <h1>会社方針</h1>
              <h4>49期 重点方針</h4>
              <ul>
                <li>経営継承への行動</li>
                <li>Co2対策への行動</li>
                <li>非対面型営業の強化</li>
                <li>危機管理対応の強化</li>
                <li>RODからDXへ進化</li>
                <li>ビジョン実現型経営計画の実績</li>
              </ul>
              <div class="doc_outwrap m_b50">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>ビジョン実現シート</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期会社方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h1>部門方針</h1>
              <h3>CS営業部</h3>
              <h4>49期 重点方針</h4>
              <ul>
                <li>オンライン、リモートを利用し新規顧客を獲得する</li>
                <li>メンテ＆エンジの売上を拡大する</li>
                <li>重要顧客からの売上を拡大する</li>
                <li>異業種や全国パートナーと連携し新たなサービスにチャレンジする</li>
                <li>企画提案型の営業にチャレンジする</li>
              </ul>
              <div class="doc_outwrap m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期 CS営業部 部門方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>エリアサービス部</h3>
              <h4>49期 重点方針</h4>
              <ul>
                <li>働く環境づくり</li>
                <li>未然防止・再発防止行動</li>
                <li>DXの推進</li>
                <li>新たな業務への取り組み</li>
              </ul>
              <div class="doc_outwrap m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期 エリアサービス部 部門方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" d="" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>環境サービス部</h3>
              <h4>49期 重点方針</h4>
              <ul>
                <li>未然防止型行動</li>
                <li>PDセンター機能アップ</li>
                <li>メンテ＆エンジ業務拡大</li>
                <li>エキスパート人材への成長</li>
              </ul>
              <div class="doc_outwrap m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期 環境サービス部 部門方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" d="" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>リサイクル部</h3>
              <h4>49期 重点方針</h4>
              <ul>
                <li>Co2対策への行動</li>
                <li>人材採用の推進</li>
                <li>働く環境整備の継続</li>
                <li>DXで防ぐ システム化の推進</li>
                <li>働く人への環境づくり（福利厚生の向上）</li>
              </ul>
              <div class="doc_outwrap m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期 リサイクル部 部門方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" d="" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>コントロール部</h3>
              <h4>49期 重点方針</h4>
              <ul>
                <li>全社のDXを牽引</li>
                <li>スキルアップで専門性の向上</li>
                <li>支援内容の強化・充実</li>
              </ul>
              <div class="doc_outwrap m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期 コントロール部 部門方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" d="" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>内部統括部</h3>
              <h4>49期 重点方針</h4>
              <ul>
                <li>コロナ対策の強化</li>
                <li>人事G・総務Gの組織及び役割強化</li>
                <li>タカヤマ人事スタイルの構築</li>
                <li>システム化への行動</li>
              </ul>
              <div class="doc_outwrap m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap">
                    <div class="doc_left"><p>49期 内部統括部 部門方針</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" d="" href="blob:http://localhost:8080/dc952f51-dff3-43f7-ae3f-3e308cb4939c">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            -->
              <h1>会社方針</h1>
              <div v-html="policy.content"></div>
              <div class="doc_outwrap m_t40 m_b50">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in policy.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h1>部門方針</h1>
              <h3>CS営業部</h3>
              <div v-html="cs_message.value"></div>
              <div class="doc_outwrap m_t40 m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in cs_message.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>エリアサービス部</h3>
              <div v-html="areaService_message.value"></div>
              <div class="doc_outwrap m_t40 m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in areaService_message.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>環境サービス部</h3>
              <div v-html="environmentService_message.value"></div>
              <div class="doc_outwrap m_t40 m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in environmentService_message.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>リサイクル部</h3>
              <div v-html="recycling_message.value"></div>
              <div class="doc_outwrap m_t40 m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in recycling_message.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>コントロール部</h3>
              <div v-html="control_message.value"></div>
              <div class="doc_outwrap m_t40 m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in control_message.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3>内部統括部</h3>
              <div v-html="internalControl_message.value"></div>
              <div class="doc_outwrap m_t40 m_b100">
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in internalControl_message.attached_file" :key="file.id">
                    <div class="doc_left"><p>{{ file.display_name }}</p></div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a class="en" target="_blank" v-on:click="dlfile(file.storage_file_name)">DL</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <!--
              <div v-html="policy.content"></div>
            -->
            </article>
          </div>
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥"></div>
          <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥"></div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/kusa02.png" alt="草"></div>
          <div class="deco_wrap03"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap06"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap07"><img src="@/assets/front_component/images/assets/kusa05.png" alt="草"></div>
          <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap09"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap10"><img src="@/assets/front_component/images/assets/kusa07.png" alt="草"></div>
          <div class="deco_wrap11"><img src="@/assets/front_component/images/assets/kusa06.png" alt="草"></div>
          <div class="deco_wrap12"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap13"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap14"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap15"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap16"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap17"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球"></div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター"></div>
          <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球"></div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球"></div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー">
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
        <ul class="menu_list">
          <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' }}">サンクス&ホメロンカード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' }}">創発カード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' }}">気づき日報</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'photolog' }}">フォトログ</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'column' }}">コラム</router-link></li>

       </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import message from "@/apis/Message";
import dayjs from 'dayjs';
import MessageType from "@/const/MessageType.json";
import $ from 'jquery';

import "@/assets/front_component/js/functions.js";

export default {
  name: "message",
  data() {
    return {
      policy: {},
      policy_messages: [],
      policy_id:"",
      loader: false,

      cs_id: "",
      cs_attached_file:[],
      cs_message: {
        key: "policy_group",
      },

      areaService_id: "",
      areaService_attached_file:[],
      areaService_message: {
        key: "policy_group",
      },

      environmentService_id: "",
      environmentService_attached_file:[],
      environmentService_message: {
        key: "policy_group",
      },

      recycling_id: "",
      recycling_attached_file:[],
      recycling_message: {
        key: "policy_group",
      },

      control_id: "",
      control_attached_file:[],
      control_message: {
        key: "policy_group",
      },

      internalControl_id: "",
      internalControl_attached_file:[],
      internalControl_message: {
        key: "policy_group",
      },
    };
  },
  components: {},
  beforeCreate(){
    $(function(){   
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $('.g_header_list').removeClass('open');
      $('#button').removeClass('active');
      $(document.body) .removeClass('open');
      var result = $('body').attr('style');
      var int_data = result.replace(/[^0-9]/g, '');
      $("html,body").css({
        "overflow": "auto",
        "height": "auto",
      });
      //bodyfixedを解除する
      $('body').css({
        'position': '',
        'width': '',
        'top': ''
      });
      $(window).scrollTop(int_data); 
      $('.g_header_list  > li.has_child').removeClass('open');
      $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.messagePolicy('policy');
  },
  methods: {
    dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          //console.log(response.headers['content-filename']);
          //console.log(response.headers['content-type']);
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
          /**
           * 疑似ダウンロード
           */
          //let link= document.createElement('a')
          //link.href= window.URL.createObjectURL(blob)
          //link.download= response.headers['content-filename']
          // // 以下コメントアウトで実行
          // link.click()
        });
    },
    messagePolicy(type) {
      this.loader = true;

      message.list(type)
      .then((response) => {
        if (response != null) {
          this.policy_messages = response.data.data;
          console.log(this.policy_messages);
          this.policy_id = this.policy_messages[0].id;
          this.getPolicy();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loader = false;
      });
    },
    getPolicy() {
      message.show(this.policy_id)
      .then(
        (response) => {
          if (response != "") {
            this.policy = response.data.data;
            console.log(this.policy);
            console.log(this.policy.content);

            message.showfile(this.policy_id,'key','attached_file')
            .then((response) => {
              if (response.data.data.length != 0) {
                this.policy.attached_file = response.data.data;
                console.log(this.policy.attached_file);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
            });
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(() => {})
      .finally(() => {});
      message.policy_show('CS営業部')
        .then((response) => {
          if (response != null) {
            console.log('CS営業部');
            console.log(response.data.data);
            this.cs_message = response.data.data[0];
            this.cs_id = response.data.data[0].id;
            console.log(this.cs_id);
            message.show_policy_file(this.cs_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.cs_message.attached_file = response.data.data;
                console.log(this.cs_message.attached_file);
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
            });
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('エリアサービス部')
        .then((response) => {
          if (response != null) {
            console.log('エリアサービス部');
            console.log(response.data.data);
            this.areaService_message = response.data.data[0];
            this.areaService_id = response.data.data[0].id;
            console.log(this.areaService_id);
            message.show_policy_file(this.areaService_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.areaService_message.attached_file = response.data.data;
                console.log(this.areaService_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('環境サービス部')
        .then((response) => {
          if (response != null) {
            console.log('環境サービス部');
            console.log(response.data.data);
            this.environmentService_message = response.data.data[0];
            this.environmentService_id = response.data.data[0].id;
            console.log(this.environmentService_id);
            message.show_policy_file(this.environmentService_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.environmentService_message.attached_file = response.data.data;
                console.log(this.environmentService_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('リサイクル部')
        .then((response) => {
          if (response != null) {
            console.log('リサイクル部');
            console.log(response.data.data);
            this.recycling_message = response.data.data[0];
            this.recycling_id = response.data.data[0].id;
            console.log(this.recycling_id);
            message.show_policy_file(this.recycling_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.recycling_message.attached_file = response.data.data;
                console.log(this.recycling_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('コントロール部')
        .then((response) => {
          if (response != null) {
            console.log('コントロール部');
            console.log(response.data.data);
            this.control_message = response.data.data[0];
            this.control_id = response.data.data[0].id;
            console.log(this.control_id);
            message.show_policy_file(this.control_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.control_message.attached_file = response.data.data;
                console.log(this.control_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
      message.policy_show('内部統括部')
        .then((response) => {
          if (response != null) {
            console.log('内部統括部');
            console.log(response.data.data);
            this.internalControl_message = response.data.data[0];
            this.internalControl_id = response.data.data[0].id;
            console.log(this.internalControl_id);
            message.show_policy_file(this.internalControl_id)
            .then((response) => {
              if (response.data.data.length != 0) {
                this.internalControl_message.attached_file = response.data.data;
                console.log(this.internalControl_message.attached_file);
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
  },
  mounted() {
    document.body.className = 'page_template';
    this.$nextTick(function() {
      $(function(){
        $('.menu_icon').click(function(){
          if($('.menu_list_wrap').hasClass('show')){
            $('.menu_list_wrap').removeClass('show');
          }else{
            $('.menu_list_wrap').addClass('show');
          }
        });
      });
      $(document).click(function(event) {
        if(!$(event.target).closest('.menu_wrap').length) {
          $('.menu_list_wrap').removeClass('show');
        }
      });
      $(function(){
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if(scroll > pagetop_show && scroll>60){
            $('.pagetop_wrap').addClass('show');
          } else {
            $('.pagetop_wrap').removeClass('show');
          }
        });
      });
      $(function(){
        $('#js-loader').delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      //Jquery
      // youtubeがタグの構成上表示出来ないので以下でタグを変換している
      $(function () {
        $("figure.media").each(function () {
          var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
          $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
          $(this)
            .children()
            .replaceWith(
              "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
            );
            console.log($y_url);
          $(this)
            .children(".post_youtube")
            .attr("src", "https://www.youtube.com/embed/" + $y_url);
        });
      });
    });
  },
  updated(){
    console.log('アップデート');
  }
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
